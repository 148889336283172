.tripCard__container {
  width: 100%;
  max-width: 400px;
  background: #ffffff;
  border: 2px solid #FFFFFF;
  box-shadow: 5px 5px 9px rgba(0, 0, 0, 0.418);
  border-radius: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 3%;
}

.tripCard__leftContainer {
  width: 100%;

  max-width: 380px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin: 10px auto;
}

.tripCard__carouselItem {
  position: relative;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
}

.tripCard__destinationImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.tripCard__rightContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
}

.tripCard__profileSection {
  display: flex;
  align-items: center;
}

.tripCard__profileImg {
  width: 40.32px;
  height: 40.32px;
  border-radius: 50%;
  margin-right: 8px;
}

.tripCard__username {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 19.2658px;
  line-height: 23px;
  color: #000000;
}

.tripCard__details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.tripCard__locations {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tripCard__detailsLeft, .tripCard__detailsRight {
  display: flex;
  flex-direction: column;
}

.tripCard-details {
  display: flex;
}

.tripCard__dateLabel, .tripCard__membersInfo, .tripCard__ageGender, .tripCard__description {
  font-family: 'Montserrat', sans-serif;
  color: #312E2E;
  margin: 4px 0;
}

.tripCard__dateLabel {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.tripCard__separatorLine {
  width: 100%;
  height: 1px;
  background: #D9D9D9;
  margin: 8px 0;
}

.tripCard__description {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16.7305px;
  line-height: 20px;
  color: #333333;
}

.tripCard__chatNow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tripCard__chatButton {
  width: 88px;
  height: 48.36px;
  background: #8DD3BB;
  border-radius: 7.92793px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 12.41px;
  line-height: 15px;
  color: #292222;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* Media Queries */
@media (min-width: 768px) {
  .tripCard__container {
    flex-direction: column;
  }

  .tripCard__leftContainer {
    margin: 10px;
  }

  .tripCard__rightContainer {
    padding: 10px;
  }
}

@media (max-width: 767px) {
  .tripCard__container {
    flex-direction: column;
  }

  .tripCard__leftContainer {
    margin: 10px auto;
  }

  .tripCard__rightContainer {
    padding: 10px;
  }

  .tripCard__chatButton {
    width: 100%;
    justify-content: center;
  }
}
