.chat-sidebar-container {
  width: 30%;

  border-style: solid;
  border-width: 0px 0.5px 0px 0px;
  border-right-color: #dcdada;
  overflow-y: auto;
  @media (max-width:768px) {
    width: 100%;
  }
}
.chat-sidebar-container {
 
  overflow-y: auto;
  background-color: #f4f4f4;
  transition: transform 0.3s ease-in-out;
}

.chat-sidebar-container.open {
  transform: translateX(0);
}

.chat-sidebar-container {
  transform: translateX(-100%);
}

/* Add responsive styles */
@media (max-width: 768px) {
  .chat-sidebar-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  }
}
