.con {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.chatpage-container {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.hamburger {
  display: none;
  background-color: #ADDFCE;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
  position: absolute;
  top: 2.5%;
  left: 2.5%;
  z-index: 1000;
}

.sidebar-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  .sidebar-overlay {
    display: block;
  }
}
