.LoginCopyrightTextContainer {
  width: fit-content;

  left: -2%;
  bottom: -3%;
  z-index: 2;
}

.LoginCopyrightText {
  font-size: 1vw;
  font-weight: 400;

  @media (max-width: 786px) {
    font-size: 2vw;
  }
}

.LoginTravmigozCopyrightText {
  font-weight: 500;
}
