/* App.css */
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif !important;
}

input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}
.App {
  text-align: center;
}
input::placeholder,
textarea::placeholder {
  font-weight: 500 !important;
  color: #787878;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #787878;
  font-weight: 500 !important;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #787878;
  font-weight: 500 !important;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #787878;
  font-weight: 500 !important;
}

input,
textarea {
  font-family: 'Montserrat', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
