.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
}

.webAppNameAndLogo {
  font-weight: 500;
  color: #42a7c3;
  font-size: 40px;
  padding: 8px 12px;
  cursor: pointer;
  cursor: pointer;
}

.otherContentsOfNavBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding: 8px 12px;
}

.navContainer {
  padding: 30px;
  z-index: 1000;
  position: absolute;
  width: 100%;
  padding-top: 0;
}

.NavContents {
  padding: 10px;
  cursor: pointer;
}

.signup {
  background-color: white;
  padding: 10px 20px;
  border-radius: 9px;
  color: black;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  margin: 0 10px;
  cursor: pointer;
}

.nav-link {
  text-decoration: none;
}

.nav-button {
  color: rgb(39, 167, 218);
  border-radius: 4px;
  border: none;
  font-weight: 500;
  margin-right: 20px;
  cursor: pointer;
}

#search-button {
  width: 50px;
}

.nav-button:hover {
  filter: drop-shadow(3px 3px 4px #228be6);
}

.listItemValueForIdx {
  position: relative;
  cursor: pointer;
  display: flex;
  gap: 0px;
  margin: 0;
  padding: 0;
  align-items: center;
  font-weight: 600;
  color: black;
}

.userProfileDropDownList {
  position: absolute;
  list-style: none;
  width: 200px;
  background-color: #fff;
  border: 1px solid #caced1;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  max-height: 200px;
  font-size: 16px;
  z-index: 2;
  margin-left: -50px;
}

.listItemValueForUserProfileData {
  padding: 10px;
  margin: 0;
}

.listItemValueForIdx:hover {
  background-color: #f2f2f2;
}

.notification-badge {
  position: absolute;
  height: 30%;
  width: 30%;
  border-radius: 50%;
  background-color: #fa3e3e;
  top: 0;
  right: 0;
}
