.chat-item {
  /* display: flex; */
  /* align-items: center; */
  /* padding: 15.6313px 20.8418px; */
  /* gap: 20.84px; */
  background: #ffffff;
  cursor: pointer;
  height: 10%;
  border-bottom-style: solid;
  border-bottom-width: 0.3px;
  border-bottom-color: #bab9b9;
}

.chat-item-sub-container {
  display: flex;
  padding: 10px 20px;
}

.chat-item.active {
  background: #DCDADA;
  /* background: #bab9b9; */
}

.avatar {
  width: 63px;
  height: 63px;
  border-radius: 50%;
  /* background-color: #8babd8; */
  /* background: url(.png); */
  /* border-radius: 130.261px; */
}

.chat-item-name-and-text {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin: 10px 20px;
  justify-content: flex-start;
  gap: 20px
  /* gap: 10px */
}

.chat-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-item-name-text {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;

}

.new-message-badge-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-message-badge {
  display: flex;
  background: #78E378;
  height: 24px;
  width: 24px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.new-message-badge-text {
  color: #ffffff;
  font-size: 12px;
  font-family: 'Arial';
  font-weight: 800;
}

.chat-message {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #707991;
  margin-top: 5px;
}
