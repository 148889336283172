.chatbox {
  position: relative;
  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.chatbox-messages {
  overflow-y: auto;
  height: 75%;
}

.chatinput-bar {
  flex: 1;

  position: relative;
  bottom: 0;
  align-items: center;

  width: 100%;
  background-color: #eeeeee;
}

.chatinput-form {
  display: flex;
  align-items: center;
}

.chatinput {
  flex: 1;
  padding: 16px 25px;
  border-style: none;
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  outline: none;
  color: black;
  background-color: #eeeeee;
}

.chat-input-emoji-picker {
  height: 30px;
  width: 30px;
  margin: 10px;
  cursor: pointer;
}

.chatinput-actions {
  justify-content: center;
  align-items: center;
  border-style: none;
}

.chatinput-submit-button {
  width: 20px;
  height: 20px;
  border-style: none;
  margin: 0px 20px;
}

.chatinput-action {
  cursor: pointer;
}
.emoji-picker {
  position: absolute !important;
  left: 0;
  bottom: 12%;
  transition: bottom 5s ease-in-out;
  z-index: 1;
}

.emoji-picker.show {
  bottom: 0;
}

.scrollable-chat-container {
  overflow-y: auto;
  padding: 40px;
}

.message-tooltip {
  position: relative;
  display: inline-block;
}

.message-container {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  position: relative;
}

.message-container.user {
  justify-content: flex-end;
}

.message-container.sender {
  justify-content: flex-start;
}

.message-content {
  padding: 7px 15px;
  border-radius: 20px;
}

.message-content.user {
  background-color: #bee3f8;
  position: relative;
  right: 0;
  border-top-right-radius: 0px;
}

.message-content.sender {
  background-color: #b9f5d0;
  position: relative;
  left: 0;
  border-top-left-radius: 0px;
}
